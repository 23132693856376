<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="力率(小数)" prop="factor">
                        <el-input-number
                            v-model="formData.factor"
                            :controls="false"
                            :min="0"
                            :max="1"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="无功/有功(最小值)" prop="minRate">
                        <el-input-number
                            v-model="formData.minRate"
                            :controls="false"
                            :min="0"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="无功/有功(最大值)" prop="maxRate">
                        <el-input-number
                            v-model="formData.maxRate"
                            :controls="false"
                            :min="0"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="调整标准（90%）" prop="adjustmentStandardOne">
                        <el-input-number
                            v-model="formData.adjustmentStandardOne"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="调整标准（85%）" prop="adjustmentStandardTwo">
                        <el-input-number
                            v-model="formData.adjustmentStandardTwo"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="调整标准（80%）" prop="adjustmentStandardThree">
                        <el-input-number
                            v-model="formData.adjustmentStandardThree"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'FactorAdjustedPowerPriceForm',
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                factor: undefined,
                minRate: undefined,
                maxRate: undefined,
                adjustmentStandardOne: undefined,
                adjustmentStandardTwo: undefined,
                adjustmentStandardThree: undefined,
            }
        };
    },
    computed: {
        formRules() {
            return {
                factor: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],
                minRate: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],
                maxRate: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],
                adjustmentStandardOne: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],
                adjustmentStandardTwo: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ],
                adjustmentStandardThree: [
                    {required: true, message: '请输入', trigger: 'blur'}
                ]
            };
        }
    },
    methods: {
        async initFormValue() {
            const {type, id} = this;
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getFactorAdjustedPowerPrice(this.id);
            }
        },
        getFactorAdjustedPowerPrice(id) {
            this.$client.getFactorAdjustedPowerPrice(id).then(ret => {
                if (ret.code === 0) {
                    for (const key in ret.data) {
                        if (ret.data[key] == null) {
                            ret.data[key] = undefined;
                        }
                    }
                    this.formData = ret.data || {};
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'error'
                    });
                }
            });
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (this.type === 'add') {
                    if (valid) {
                        this.$client.insertFactorAdjustedPowerPrice(this.formData).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                } else if (valid) {
                    this.$client.updateFactorAdjustedPowerPrice(this.id, this.formData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
/deep/ [label][form] {
    width: 100%;
    text-align: left;
}
</style>
