<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
            <el-button type="primary" size="mini" @click="addHandle" v-if="$authCheck('business:factorAdjustedPowerPrice:save')" style="border-radius: 4px;">+ 新建</el-button>
<!--            <el-button type="primary" size="mini" @click="editHandle">编辑</el-button>-->
<!--            <el-button type="primary" size="mini" @click="showDetail">详情</el-button>-->
<!--            <el-button type="primary" size="mini" @click="deleteHandle">删除</el-button>-->
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.pageFactorAdjustedPowerPrice"
                :form-options="formOptions"
                :columns="columns"
                @selection-change="selectHandle"
            >
                <template slot="append">
                    <el-table-column
                        align="center"
                        label="操作"
                        type="action"
                        width="160"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="详情" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_reset_password"
                                    @click="showDetail(scope.row.id)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="编辑" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_edit"
                                    @click="editHandle(scope.row.id)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_delete"
                                    @click="deleteHandle(scope.row.id)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <el-dialog
            width="760px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <factor-adjusted-power-price-form ref="editForm" :id="dialog.currentId" :type="dialog.type"></factor-adjusted-power-price-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="closeModalHandle" size="mini">
            {{dialog.type === 'detail' ? '关闭' : '取消'}}
        </el-button>
        <el-button
            type="primary"
            @click="submitHandle"
            size="mini"
            v-if="dialog.type !== 'detail'"
        >确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>
<script>
import numeral from 'numeral';
import FactorAdjustedPowerPriceForm from './Form';

export default {
    name: 'FactorAdjustedPowerPrice',
    components: {FactorAdjustedPowerPriceForm},
    data() {
        return {
            columns: [
                /*{
                    type: 'selection'
                },*/
                {
                    prop: 'factor',
                    label: '力率(%)',
                    formatter: (row, column, value) => {
                        return numeral(value).format('0%');
                    }
                },
                {
                    prop: 'rate',
                    label: '无功/有功（比值）',
                    width: '150',
                    formatter: (row) => {
                        const {minRate, maxRate} = row;
                        return numeral(minRate).format('0.0000') + ' ~ ' + numeral(maxRate).format('0.0000');
                    }
                },
                {
                    label: '调整标准',
                    children: [
                        {
                            prop: 'adjustmentStandardOne',
                            label: '90%',
                            formatter: (row, column, cellValue) => {
                                return numeral(cellValue).format('+0.0000');
                            }
                        },
                        {
                            prop: 'adjustmentStandardTwo',
                            label: '85%',
                            formatter: (row, column, cellValue) => {
                                return numeral(cellValue).format('+0.0000');
                            }
                        },
                        {
                            prop: 'adjustmentStandardThree',
                            label: '80%',
                            formatter: (row, column, cellValue) => {
                                return numeral(cellValue).format('+0.0000');
                            }
                        }
                    ]
                }
            ],
            formOptions: {
                forms: [
                    {
                        prop: 'factor',
                        itemType: 'inputNumber',
                        clearable: true,
                        placeholder: '请输入力率',
                        modelValue: undefined,
                        defaultValue: undefined,
                        controls: false
                    },
                    {
                        prop: 'rate',
                        itemType: 'inputNumber',
                        clearable: true,
                        placeholder: '请输入有功/无功（比值）',
                        modelValue: undefined,
                        defaultValue: undefined,
                        controls: false,
                    }
                ],
            },
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                type: ''
            }
        };
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add'
            };
        },
        editHandle(id) {
            if (id) {
                console.log(this.selectedRows);
                this.dialog = {
                    currentId: id,
                    title: '编辑',
                    visible: true,
                    type: 'edit'
                };
            }
        },
        deleteHandle(id) {
            if (id) {
                this.$confirm('确认删除？', {
                    type: 'warning'
                }).then(() => {
                    const {selectedRows} = this;
                    const ids = [];
                    ids.push(id);
                    this.$client
                        .deleteFactorAdjustedPowerPrices(ids)
                        .then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.$refs.searchTable.searchHandler();
                        })
                        .catch(() => {
                            this.$message({
                                message: '操作失败！',
                                type: 'error'
                            });
                        });
                });
            }
        },
        showDetail(id) {
            if (id) {
                this.dialog = {
                    currentId: id,
                    title: '详情',
                    visible: true,
                    type: 'detail'
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    },
};
</script>
